<template>
    <v-container class="bg-transparent-variant">
        <v-row>
            <v-col cols="12" md="6" sm="12">
                <v-card variant="flat" class="mx-auto d-flex" height="100%" min-height="600" min-width="300"
                    theme="light" color="transparent">
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <p class="text-h3 font-weight-bold text-blue-darken-2">A two-way</p>
                                    <p class="text-h3">realtime connection</p>
                               
                                    <br>
                                    <br>
                                    <p class="text-h6">
                                        ConnXions is an on-demand, B2B IT Resource Marketplace, connecting Mid-Size Enterprises with your verified resources.
                                    </p><br>
                                    <p class="text-h6">
                                        ConnXions empowers an elastic workforce by vetting your talent and simplified contracting to maximize your bench.
                                    </p>
                                </v-col>
                            </v-row>
                            <br>
                            <br>
                            <br>
                            <v-row>
                                <v-col cols="12">
                                    <v-card class="pa-5" color="blue-darken-1">
                                        <p class="text-h6">
                                        Project Boards provide you visibility into new projects that need your team's expertise. Marketplace gives prospective clients visibility into your team's expertise. 
                                    </p>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6" sm="12">
                <v-card variant="flat">
                    <v-card-text>
                        <p class="text-h6">Check out this short video to discover how our platform can work for you</p>
                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/s4jGDqJCfSM" frameborder="0"></iframe>
                </v-card-text>
                </v-card>
                <!--
                <v-card class="rounded-xl" height="100%" min-height="600" min-width="300" elevation="13"
                    :image="require('@/assets/two-way.jpg')">
                </v-card>-->
            </v-col>
        </v-row>
    </v-container>
</template>