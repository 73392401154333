<template>
  <v-main>
    <router-view />
  </v-main>
</template>

<script setup>
  //
</script>

<script>
export default {
    name: "DefaultView",
};
</script>