<template>
    <section id="section1">
        <v-card variant="flat" class="mx-auto d-flex align-center justify-center" height="400" min-height="200" min-width="300" :image="require('@/assets/connxions-delivers-background.png')" theme="dark" color="transparent">
            <span class="headline">
            <p class="centered">{{ $t('requestDemo') }}</p>
        </span>
        </v-card>
    </section>
    <section id="section2">
        <v-container>
            <v-alert v-if="error" color="error" icon="$error" closable variant="tonal" :close-label="$t('close')"
                :text="errorDescription"></v-alert>
            <v-card v-if="!progress && !success" variant="flat" class="mx-auto" max-width="800" min-height=150>
                <v-card-text class="text-center">
                    <p class="text-h5">{{ $t('demoRequestTitle') }}</p>
                </v-card-text>
                <v-form ref="form" @submit.prevent="submit()" validation-on="submit" v-model="valid">
                    <v-card variant="flat" class="mx-auto" max-width="500">
                        <v-list lines="three">
                            <v-list-subheader>{{ $t('perspective') }}</v-list-subheader>
                            <v-radio-group v-model="form.type">
                                <v-list-item>
                                    <template v-slot:prepend>
                                        <v-list-item-action start>
                                            <v-radio value="CONSULTING_PARTNER"></v-radio>
                                        </v-list-item-action>
                                    </template>
                                    <v-list-item-title>
                                        {{ $t('serviceProvider') }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap mt-1">
                                        {{ $t('serviceProviderDescription') }}
                                    </v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item>
                                    <template v-slot:prepend>
                                        <v-list-item-action start>
                                            <v-radio value="CUSTOMER"></v-radio>
                                        </v-list-item-action>
                                    </template>
                                    <v-list-item-title>
                                        {{ $t('enterprise') }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ $t('enterpriseDescription') }}
                                    </v-list-item-subtitle>
                                </v-list-item>
                            </v-radio-group>
                        </v-list>
                        <v-text-field name="name" variant="outlined" type="input" v-model="form.name"
                            :placeholder="$t('preferredName')" required :rules="rules.required" autofocus
                            autocomplete></v-text-field>
                        <v-text-field name="company" variant="outlined" type="input" v-model="form.company"
                            :placeholder="$t('companyName')" required :rules="rules.required"
                            autocomplete></v-text-field>
                        <v-text-field name="email" variant="outlined" type="email" v-model="form.email"
                            :placeholder="$t('email')" required :rules="rules.email" autocomplete></v-text-field>
                        <v-text-field name="title" variant="outlined" type="input" v-model="form.title"
                            :placeholder="$t('title')" required :rules="rules.required" autocomplete></v-text-field>
                        <v-textarea name="message" variant="outlined" type="input" v-model="form.message"
                            :placeholder="$t('message')" required :rules="rules.required"></v-textarea>
                        <v-btn @click="submit()" size="large" block color="primary">{{ $t('submit') }}</v-btn>
                    </v-card>
                </v-form>
            </v-card>
            <v-fade-transition hide-on-leave>
                <v-card v-if="progress" variant="flat" class="mx-auto" max-width="800">
                    <v-card-text>
                        <div class="py-12 text-center">
                            <v-progress-circular color="blue-grey" :size="70" indeterminate></v-progress-circular>
                        </div>
                    </v-card-text>
                </v-card>
            </v-fade-transition>
            <v-fade-transition hide-on-leave>
                <v-card v-if="success" variant="flat" class="mx-auto" max-width="800">
                    <v-card-text>
                        <div class="py-12 text-center">
                            <v-icon class="mb-6" color="success" icon="mdi-check-circle-outline" size="128"></v-icon>
                            <div class="text-h6 font-weight-bold">{{ $t('thanksForYourInterest') }}</div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-fade-transition>
        </v-container>
    </section>
    <section id="footer">
        <app-footer></app-footer>
    </section>
</template>
<script setup>
import AppFooter from '@/components/AppFooter.vue';
</script>
<script>
export default {
    name: "ContactUs",
    computed: {
        api() {
            return process.env.VUE_APP_API_ENDPOINT + '/events/contact';
        }
    },
    data() {
        return {
            valid: false,
            form: { demo: true, type: "CONSULTING_PARTNER" },
            rules: {},
            progress: false,
            success: false,
            error: false,
            errorDescription: null
        };
    },
    methods: {
        submit() {
            this.rules = {
                required: [v => !!v || 'Required'],
                email: [
                    v => !!v || 'Required',
                    v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
                ]
            }
            this.$refs.form.validate().then(() => {
                this.progress = true;
                if (this.valid) {
                    this.axios.post(this.api, this.form, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(() => {
                        this.form = { demo: true, type: "CONSULTING_PARTNER" };
                        this.success = true;
                        setTimeout(() => { this.success = false }, 5000);
                    }).catch((error) => {
                        this.error = true;
                        this.errorDescription = error.response.data.errorDescription;
                    }).finally(() => {
                        this.rules = {};
                        this.progress = false;
                    });  
                }
            }).catch((error) => {
                console.log(error);
            });
        },
    },
};
</script>

<style scoped>

.headline {
    color: white;
    font-size: 1.875em;
    display: table;
    height: 100%;
    width: 100%;
}

.centered {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

#section1 {
    background-color: black;
}

#section2 {
    margin-top: 20px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#footer {
    height: 10vh;
}
</style>