<template>
    <v-container class="bg-transparent-variant">
        <v-row>
            <v-col cols="12" md="6" sm="12">
                <v-card class="card d-flex align-center justify-center" variant="flat">
                    <v-card-text>
                                <p class="text-h3">Meeting Project Demands</p>
                                <p class="text-h3 font-weight-bold text-indigo">with Efficiency</p>
                                <br>
                                <br>
                                <p class="text-h6">
                                    At ConnXions, we recognize the evolving demands of the IT professional services market. Our B2B elastic workforce solution creates a marketplace connecting businesses with top IT talent. With ConnXions, companies can efficiently scale their IT capabilities and secure their digital future.
                                </p><br>
                                <p class="text-h6">
                                   Our mission is to transform the way organizations interact with their IT service providers, making it easier, faster, and more efficient to find, engage, and manage the right partners. By providing a seamless and transparent experience, we help you unlock the full potential of your IT investments and drive business success.
                                </p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6" sm="12">
                <v-card class="rounded-xl" height="100%" min-height="600" min-width="300" elevation="13"
                    :image="require('@/assets/section-three-top-right.jpg')"></v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped>
.card {
    height: 100%;
}
</style>