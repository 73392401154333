<template>
    <v-card variant="flat" color="#263238">
      <v-container class="py-16">
        <v-row justify="center">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="text-center pa-3">
                <p class="mission-text ">
                    {{ $t('mission') }}
                </p>
            </v-col>
        </v-row>
      </v-container>
    </v-card>
</template>

<script>
export default {
    data: () => ({
        
    }),
    methods: {
        
    }
}
</script>

<style scoped>
.py-16 {
  padding-top: 64px;
  padding-bottom: 16px;
}

.mission-text {
  font-size: 24px;
  color: white;
}

</style>