<template>
    <v-container class="bg-transparent-variant mt-5 mb-5">
        <v-row>
            <v-col cols="12">
                <v-card variant="outlined" class="mx-auto rounded-xl d-flex align-center justify-center" min-height="600" min-width="300" :image="require('@/assets/section-three-bottom.png')" theme="dark" color="white">
                    <v-card-text class="mr-5">
                    <v-row>
                        <v-col sm="6" md="5" lg="4" class="offset-sm-6 offset-md-7 offset-lg-8">
                            <p class="text-h6">For Business</p>
                            <p class="text-h3">Search for</p>
                            <p class="text-h3 font-weight-bold">the Right Talent</p><br>
                            <p class="text-h6">
                                ConnXions' intuitive, AI-driven talent search platform connects you with certified IT professionals globally. Start exploring our vast network of talent and discover the perfect fit for your needs.
                            </p>
                            <br>
                            <p>
                                <v-btn variant="outlined" size="large" rounded="xl" color="white" class="pl-10 pr-10" to="/new-tech-talent-marketplace">
                {{$t('learnMore')}}
            </v-btn>
                            </p>
                        </v-col>
                    </v-row>
                </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card variant="outlined" class="mx-auto rounded-xl d-flex align-center justify-center" min-height="600" min-width="300" :image="require('@/assets/section-four-top.png')" theme="dark" color="white">
                    <v-card-text class="ml-5">
                    <v-row>
                        <v-col cols="12" xl="4" lg="6" md="8" sm="10">
                            <p class="text-h6">For Service Providers</p>
                            <p class="text-h3">Search for</p>
                            <p class="text-h3 font-weight-bold">your Next Opportunity</p><br>
                            <p class="text-h6">
                                Service Providers, come find your next enterprise customers from the ConnXions platform. Increase your services professionals' utilization rate and your company's profitability. ConnXions makes it easy for you to find projects worldwide with top-tier companies.
                            </p>
                            <br>
                            <p>
                                <v-btn variant="outlined" size="large" rounded="xl" color="white" class="pl-10 pr-10" to="/evolved-tech-project-access">
                                    {{ $t('learnMore') }}
                                </v-btn>
                            </p>
                        </v-col>
                    </v-row>
                </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped>

.card {
    height: 100%;
  }

</style>

<script>
export default {
    name: "SectionThree",
    computed: {
        baseUrl() {
            return process.env.VUE_APP_CONSOLE_BASE_URL;
        }
    }
};
</script>