<template>
    <v-container class="bg-transparent-variant">
        <v-snackbar v-model="error" timeout="5000" location="top" width="800" rounded color="error">{{ errorDescription }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="error = false">
                    {{ $t("close") }}
                </v-btn>
            </template>
        </v-snackbar>
        <v-row>
            <v-col cols="12">
                <v-card variant="flat" class="mx-auto d-flex text-center" 
                    theme="light" color="transparent">
                        <v-card-text>
                                    <p class="text-h4 font-weight-bold text-blue-darken-2">Maximize your bench Utilization with our Limited Time Offer</p>
                                    <br>
                                    <br>
                                    <p class="text-h5 text-white">
                                        Become an early adopter by joining our esteemed network of service providers, distinguished for their expertise in Cloud Services and Artificial Intelligence (AI). Our network supports the following leading software companies: AWS, Google Cloud Platform, Microsoft Azure, Red Hat, ServiceNow, Oracle, Salesforce and many more.
                                    </p><br><br><br>
                                    <p class="text-h5 text-white">
                                        ConnXions is waiving its 10% service fee on your first IT project up to $25,000.
                                    </p><br>
                                    <!--
                                    <v-btn variant="outlined" size="large" rounded="xl" color="white" class="pl-10 pr-10" @click="signUp()">
                            {{ $t("getStartedToday") }}
                        </v-btn>-->

                        </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <br>
        <v-row>
            <v-col cols="12" class="text-center align-center justify-center">
                <v-form v-if="!progress && !success" ref="form" @submit.prevent="submit()" validation-on="submit" v-model="valid">
                    <v-card variant="flat" class="mx-auto" max-width="550" color="transparent">    
                        <v-text-field theme="dark" name="preferredName" variant="outlined" type="input" v-model="form.preferredName"
                            required :rules="rules.required" :placeholder="$t('preferredName')" class="text-white"
                            autocomplete></v-text-field>
                        <v-text-field theme=dark name="company" variant="outlined" type="input" v-model="form.companyName"
                            :placeholder="$t('companyName')" required :rules="rules.required" class="text-white"
                            autocomplete></v-text-field>
                        <v-text-field theme="dark" name="email" variant="outlined" type="email" v-model="form.email" 
                            :placeholder="$t('email')" required :rules="rules.email" class="text-white" autocomplete></v-text-field>
                        <v-text-field name="title" variant="outlined" type="input" v-model="form.title" 
                            :placeholder="$t('title')" required :rules="rules.required" class="text-white" autocomplete></v-text-field>
                        <v-text-field name="website" variant="outlined" type="input" v-model="form.website" 
                            :placeholder="$t('website')" required :rules="rules.required" class="text-white" autocomplete></v-text-field>
                        <v-btn theme=dark variant="outlined" size="large" rounded="xl" color="white" class="pl-10 pr-10" @click="submit()">
                            {{ $t("signUp") }}
                        </v-btn>
            </v-card>
        </v-form>
        <v-fade-transition hide-on-leave>
                <v-card v-if="progress" variant="flat" class="mx-auto" max-width="800">
                    <v-card-text>
                        <div class="py-12 text-center">
                            <v-progress-circular color="blue-grey" :size="70" indeterminate></v-progress-circular>
                        </div>
                    </v-card-text>
                </v-card>
            </v-fade-transition>
            <v-fade-transition hide-on-leave>
                <v-card v-if="success" elevation="12" class="mx-auto" max-width="800">
                    <v-card-text>
                        <div class="py-12 text-center">
                            <v-icon class="mb-6" color="success" icon="mdi-check-circle-outline" size="128"></v-icon>
                            <div class="text-h6 font-weight-bold">{{ $t('thanksForYourInterest') }}</div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-fade-transition>  
            </v-col>
        </v-row>
        <br>
        <v-divider color="white"></v-divider>
        <br>
        <v-row>
            <v-col cols="12" class="text-center text-white">
                <span class="text-h4">Hear what our clients have to say</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6" sm="12">
                <v-card variant="flat" theme="dark" class="fill-height">
                    <v-card-text>
                        <p class="text-h5">
                            "This is the Uber model for IT project resources! In a fractured model as it is today, the market is ripe for the ConnXions platform"
                        </p><br>
                        <p class="text-h5 text-blue-darken-2">Malcolm Nicholls</p>
                        <p>Senior Board Member<br>IT Staffing Firm</p>
                    </v-card-text>        
                </v-card>    
            </v-col>
            <v-col cols="12" md="6" sm="12">
                <v-card variant="flat" theme="dark" class="fill-height">
                    <v-card-text>
                        <p class="text-h5">
                            "ConnXions has developed a cutting-edge B2B Marketplace, I love it! It solves a huge industry problem, giving enterprise companies the ability to find verified IT consultants for their projects."
                        </p>
                        <br>
                        <p class="text-h5 text-blue-darken-2">Sanjay Kommera</p>
                        <p>Chief Executive Officer (CEO)<br>AccelESG</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "TryNow",
    computed: {
        api() {
            return process.env.VUE_APP_API_ENDPOINT + '/registrations';
        }
    },
    data() {
        return {
            valid: false,
            form: { type: "RESOURCE_MANAGER" },
            rules: {},
            progress: false,
            success: false,
            error: false,
            errorDescription: null
        };
    },
    methods: {
        submit() {
            this.rules = {
                required: [v => !!v || 'Required'],
                email: [
                    v => !!v || 'Required',
                    v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
                ]
            }
            this.$refs.form.validate().then(() => {
                this.progress = true;
                if (this.valid) {
                    this.axios.post(this.api, this.form, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(() => {
                        this.form = { type: "RESOURCE_MANAGER" };
                        this.success = true;
                        setTimeout(() => { this.success = false }, 5000);
                    }).catch((error) => {
                        this.error = true;
                        if (error.response.data.error === "DUPLICATE_KEY") {
                            this.errorDescription = "Your email is already registered."
                        } else {
                            this.errorDescription = error.response.data.errorDescription;
                        }
                    }).finally(() => {
                        this.rules = {};
                        this.progress = false;
                    });  
                }
            }).catch((error) => {
                console.log(error);
            });
        },
    },
};

</script>

<style scoped>



</style>