
<template>
    <v-container class="py-16">
        <h4 class="text-h4 font-weight-bold mb-4">{{ $t('features') }}</h4>
        <p class="vision-text mb-4">
                    {{ $t('VISION') }}
                </p>
        <v-row>
            <v-col v-for="(feature, i) in features" :key="i" cols="12" sm="12" md="12" lg="6" xl="6">
                <v-card variant="flat" height="100">
                    <h5 class="text-h5 font-weight-bold mt-2 mb-2 text-wrap">{{ feature.title }}</h5>
                    <p class="text-body-1">{{ feature.description }}</p>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
export default {
    data() {
        return {
            features: [
                {
                    title: 'Professional services marketplace',
                    description: 'Our Professional Services Marketplace is a one-stop-shop for businesses to find and engage with certified consultants from our network of partners. Whether you need help with a specific project, require ongoing support, or simply want to tap into expert knowledge, our platform connects you with a curated network of trusted and vetted professionals.',
                },
                {
                    title: 'AI Powered Contract Management',
                    description: 'Our Contract Management with Generative Text feature harnesses the power of artificial intelligence to simplify and streamline contract creation, negotiation, and management. With our innovative solution, you can generate Requests for Proposals (RFPs) in minutes, not hours, and ensure that all agreements are accurate, compliant, and up-to-date.',
                },
                {
                    title: 'Service Provider management',
                    description: 'Our Full Lifecycle Service Provider Management features provide a comprehensive platform for managing your service provider relationships from start to finish. From onboarding and contract management to performance monitoring and offboarding, our solution helps you streamline and optimize your interactions with service providers.',
                },
                {
                    title: 'Proposal Evaluation with Standardized Review and Scoring',
                    description: 'Our Standardized Proposal Review feature provides a structured and transparent process for receiving and evaluating proposals, ensuring that all submissions are reviewed consistently and objectively. With our solution, you can create customized evaluation criteria, assign weights to each criterion, and score proposals based on their alignment with your requirements.',
                },
                {
                    title: 'Access to our network of service providers',
                    description: 'Get instant, on-demand access to our extensive network of trusted and vetted service providers. Our platform connects you with a diverse range of experts from established companies, who have been thoroughly vetted for quality, reliability, and expertise.',
                },
            ],
        }
    },
}
</script>
  
  <style scoped>
  .py-16 {
    padding-top: 16px;
    padding-bottom: 32px;
  }
  .h-100 {
    height: 100%;
  }

  .vision-text {
  font-size: 20px;
  color: black;
}
  </style>