<template>
  <v-container class="benefits">
    <v-row justify="center">
      <v-col cols="12">
        <p class="text-h3">Unlock the Power of Our Platform</p>
        <p class="text-h5">
          As a strategic leader who is responsible for successful delivery of technology initiatives, you will:
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="4" xl="4" class="fill-height d-flex flex-column justify-center align-center">
        <v-card class="benefit" elevation="10" height=290>
          <v-icon size="64" color="primary">mdi-rocket</v-icon>
          <v-card-title class="benefit-title">Accelerate Innovation</v-card-title>
          <v-card-text class="benefit-text">Modernize partner discovery and engagement with artificial intelligence-powered RFP automation to identify ideal service providers, saving you time and effort while unlocking new business opportunities.</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="4" xl="4">
        <v-card class="benefit" elevation="10" height=290>
          <v-icon size="64" color="primary">mdi-chart-line</v-icon>
          <v-card-title class="benefit-title">Achieve Business Success</v-card-title>
          <v-card-text class="benefit-text">Effectively manage relationships with service providers through our
            platform, ensuring that you get the most value from your partnerships
            and can focus on delivering successful technology initiatives that drive
            business outcomes.</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="4" xl="4">
        <v-card class="benefit" elevation="10" height=290>
          <v-icon size="64" color="primary">mdi-arrow-expand-horizontal</v-icon>
          <v-card-title class="benefit-title">Expand Your Network</v-card-title>
          <v-card-text class="benefit-text">Tap into our extensive network of pre-vetted service providers, expanding
            your access to specialized expertise and capabilities that can help
            drive business growth and competitiveness.</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'BenefitsCard',
}
</script>

<style scoped>
.benefits {
  text-align: center;
}

.benefit {
  padding: 20px;
  margin: 5px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.benefit-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.benefit-text {
  font-size: 14px;
  color: #666;
}
</style>