<template>
   <v-container>
        <v-row>
            <v-col cols="12" lg="6" md="12" class="fill-height d-flex flex-column justify-center align-center">
                <v-card variant="flat" max-width="1000">
                    <v-card-text class="text-center">
                        <p class="text-h4">Your <span class="text-blue-accent-3">Business to Business</span></p>
                        <p class="text-h4">Professional Services Marketplace</p>
                        <p class="text-h6">Discover who we are and how we can transform your technology service provider contracting - all in under a minute</p>
                        <img :src="require('@/assets/images/blue-arrow-right.png')">
                        <p class="text-h4">Still Not Convinced</p>
                        <p class="text-h6">Watch a More in Depth Demo Recording Now</p><br>
                        <v-btn size="large" variant="flat" color="#2979FF" rounded="xl" href="https://calendly.com/customersupport-connxions/demo" target="_blank">Access Now</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" lg="6" md="12" class="fill-height d-flex flex-column justify-center align-center">
                <v-card variant="flat" max-width="1000">
                    <v-card-text class="text-center">
                        <p class="text-h6 mb-1 pr-3 pl-3">Check out this short video to discover how our platform can work for you</p>
                        <iframe width="100%" height="400" src="https://www.youtube.com/embed/6Guiaw3UDYQ"
                            frameborder="0"></iframe>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'BenefitsVideo',
}
</script>

<style scoped>


</style>