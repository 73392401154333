<template>
<v-container id="demo-request" fill-height class="bg-transparent-variant">
    <v-snackbar v-model="error" timeout="5000" location="top" width="800" rounded color="error">{{ errorDescription }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="error = false">
                    {{ $t("close") }}
                </v-btn>
            </template>
        </v-snackbar>
    <v-row>
        <v-col cols="12">
            <v-card v-if="!progress && !success" variant="flat" class="mx-auto d-flex text-center" theme="light" color="transparent">
        <v-card-text class="text-center">
            <p class="text-h4 font-weight-bold text-blue-darken-2">{{ $t('demoRequestTitle') }}</p>
        </v-card-text>
        </v-card>
        </v-col>
    </v-row>
    <br>
    <v-row>
        <v-col cols="12" class="text-center align-center justify-center">
            <v-form v-if="!progress && !success" ref="form" @submit.prevent="submit()" validation-on="submit" v-model="valid">
                <v-card variant="flat" class="mx-auto" max-width="550" color="transparent">  
                <v-text-field theme="dark" name="name" variant="outlined" type="input" v-model="form.name"
                    :placeholder="$t('preferredName')" class="text-white" required :rules="rules.required"
                    autocomplete></v-text-field>
                <v-text-field theme="dark" name="company" variant="outlined" type="input" v-model="form.company"
                    :placeholder="$t('companyName')" class="text-white" required :rules="rules.required"
                    autocomplete></v-text-field>
                <v-text-field theme="dark" name="email" variant="outlined" type="email" v-model="form.email"
                    :placeholder="$t('email')" class="text-white" required :rules="rules.email" autocomplete></v-text-field>
                <v-text-field theme="dark" name="title" variant="outlined" type="input" v-model="form.title"
                    :placeholder="$t('title')" class="text-white" required :rules="rules.required" autocomplete></v-text-field>
                <v-textarea theme="dark" name="message" variant="outlined" type="input" v-model="form.message"
                    :placeholder="$t('message')" class="text-white" required :rules="rules.required"></v-textarea>
                <v-btn @click="submit()" theme=dark variant="outlined" size="large" rounded="xl" color="white" class="pl-10 pr-10">{{ $t('submit') }}</v-btn>
            </v-card>
        </v-form>
        <v-fade-transition hide-on-leave>
        <v-card v-if="progress" variant="flat" class="mx-auto" max-width="800" color="transparent" height="600">
            <v-card-text>
                <div class="py-12 text-center">
                    <v-progress-circular color="white" :size="70" indeterminate></v-progress-circular>
                </div>
            </v-card-text>
        </v-card>
    </v-fade-transition>
    <v-fade-transition hide-on-leave>
        <v-card v-if="success" elevation="12" class="mx-auto" max-width="800">
            <v-card-text>
                <div class="py-12 text-center">
                    <v-icon class="mb-6" color="success" icon="mdi-check-circle-outline" size="128"></v-icon>
                    <div class="text-h6 font-weight-bold">{{ $t('thanksForYourInterest') }}</div>
                </div>
            </v-card-text>
        </v-card>
    </v-fade-transition>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: "ContactUs",
    computed: {
        api() {
            return process.env.VUE_APP_API_ENDPOINT + '/events/contact';
        }
    },
    data() {
        return {
            valid: false,
            form: { demo: true, type: "CONSULTING_PARTNER" },
            rules: {},
            progress: false,
            success: false,
            error: false,
            errorDescription: null
        };
    },
    methods: {
        submit() {
            this.rules = {
                required: [v => !!v || 'Required'],
                email: [
                    v => !!v || 'Required',
                    v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
                ]
            }
            this.$refs.form.validate().then(() => {
                this.progress = true;
                if (this.valid) {
                    this.axios.post(this.api, this.form, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(() => {
                        this.form = { demo: true, type: "CONSULTING_PARTNER" };
                        this.success = true;
                        setTimeout(() => { this.success = false }, 5000);
                    }).catch((error) => {
                        this.error = true;
                        this.errorDescription = error.response.data.errorDescription;
                    }).finally(() => {
                        this.rules = {};
                        this.progress = false;
                    });  
                }
            }).catch((error) => {
                console.log(error);
            });
        },
    },
};
</script>