<template>
    <v-container class="bg-transparent-variant">
        <v-row>
            <v-col cols="12">
                <v-card variant="flat" class="mx-auto rounded-xl d-flex align-center" min-height="600" min-width="300" :image="require('@/assets/raleigh-city-view.jpg')"
                    theme="dark" color="white">
                    <v-card-text class="ml-5">
                    <v-row>
                        <v-col cols="12" xl="4" lg="6" md="8" sm="10">
                            <p class="text-h3 text-white">Making ConnXions
                                    <span class="text-h3 text-white font-weight-bold">Worldwide</span></p><br>
                                    <p class="text-h6 text-white">
                                        Our journey began in 2022 with a vision to revolutionize the IT professional
                                        services industry. Founded in Raleigh, North Carolina, ConnXions is a trusted
                                        digital marketplace, connecting enterprises with certified IT professionals
                                        worldwide.
                                    </p>
                                    <br>
                                    <p class="text-h6 text-white">
                                        We deliver certified, trusted resources to enterprises worldwide, enabling them
                                        to meet their IT project demands with ease and efficiency. We are committed to
                                        fostering connections between skilled professionals and businesses, empowering
                                        both parties to succeed and thrive in an ever-evolving digital landscape.
                                    </p>
                        </v-col>
                    </v-row>
                </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>