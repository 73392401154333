<template>
    <v-container class="mt-10 mb-10">
        <v-row>
            <v-col cols="12" class="text-center">
                <span class="header text-orange">ConnXions</span>&nbsp;
                    <span class="header text-white">Delivers</span>
            </v-col>
            <v-col xl="6" lg="12" md="12" sm="12" xs="12" v-for="item in benefits" v-bind:key="item.number">
                <v-card variant="outlined" class="d-flex align-center justify-center rounded-xl mb-3" theme="dark"
                    color="white" min-height="300" max-height="600" min-width="300">
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="auto">
                                    <v-img rounded width="150" height="150" min-width="100" min-height="100" max-width="200" max-height="200"
                                        :src="formatImage(item.image)" aspect-ratio="1"></v-img>
                                </v-col>
                                <v-col>
                                    <p class="title">{{ item.title }}</p>
                                    <p class="title-bold">{{ item.subtitle }}</p>
                                    <p class="text-h6">
                                        {{ item.text }}
                                    </p>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "OurBenefits",
    data: () => ({
        "benefits": [
            { "number": 1, "image": "cost-savings.png", "title": "Cost", "subtitle": "Savings", "text": "Optimize your budget by leveraging certified resources through our expert project management tools and seamless project workflow, unlocking maximum value while minimizing cost." },
            { "number": 2, "image": "faster-turnaround.png", "title": "Faster", "subtitle": "Turnaround", "text": "Leveraging our platform to connect with skilled professionals, clients have enjoyed accelerated turnaround times, empowering them to outpace competitors and maintain a competitive edge." },
            { "number": 3, "image": "improved-efficiency.png", "title": "Improved", "subtitle": "Efficiency", "text": "Unlock heightened efficiency with streamlined processes and access to premier talent, enabling clients to achieve remarkable results while optimizing resources." },
            { "number": 4, "image": "client-satisfaction.png", "title": "Client", "subtitle": "Satisfaction", "text": "Experience unparalleled satisfaction with our platform's streamlined processes and access to certified talent, guaranteeing seamless operations and exceptional productivity." }
        ],
    }),
    methods: {
        formatImage(value) {
            return require("@/assets/" + value);
        }
    }
};
</script>

<style scoped>
@media screen and (min-width: 1265px) {
    span.header {
        font-size: 5em;
        font-weight: bold;
        line-height: 70px;
    }
}

@media screen and (max-width: 1264px) {
    span.header {
        font-size: 3em;
        font-weight: bold;
        line-height: 50px;
    }
}

@media screen and (max-width: 960px) {
    span.header {
        font-size: 3em;
        font-weight: bold;
        line-height: 50px;
    }
}

@media screen and (max-width: 600px) {
    span.header {
        font-size: 3em;
        font-weight: bold;
        line-height: 30px;
    }
}


@media screen and (min-width: 1265px) {
    p.title-bold {
        font-size: 5em;
        font-weight: bold;
        line-height: 70px;
        color: white;
    }
}

@media screen and (max-width: 1264px) {
    p.title-bold {
        font-size: 4em;
        font-weight: bold;
        line-height: 50px;
        color: white;
    }
}

@media screen and (max-width: 960px) {
    p.title-bold {
        font-size: 3em;
        font-weight: bold;
        line-height: 50px;
        color: white;
    }
}

@media screen and (max-width: 600px) {
    p.title-bold {
        font-size: 2em;
        font-weight: bold;
        line-height: 30px;
        color: white;
    }
}

@media screen and (min-width: 1265px) {
    p.title {
        font-size: 5em;
        line-height: 70px;
        color: white;
    }
}

@media screen and (max-width: 1264px) {
    p.title {
        font-size: 4em;
        line-height: 50px;
        color: white;
    }
}

@media screen and (max-width: 960px) {
    p.title {
        font-size: 3em;
        line-height: 50px;
        color: white;
    }
}

@media screen and (max-width: 600px) {
    p.title {
        font-size: 2em;
        line-height: 30px;
        color: white;
    }
}
</style>