<template>
    <v-card variant="flat" color="#263238">
        <v-container class="py-16">
            <v-row justify="center">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="text-center pa-3">
                    <p class="orange-text text-h4">
                        Do you struggle to find the right partner for your technology projects?
                    </p>
                    <p class="what-we-do-text text-h4">
                        {{ $t('WHAT_WE_DO') }}
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
}
</script>

<style scoped>
.py-16 {
  padding-top: 64px;
  padding-bottom: 64px;
}

.orange-text {
  color: #EE8254;
}

.what-we-do-text {
  font-size: 24px;
  color: white;
}

</style>