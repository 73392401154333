import { createRouter, createWebHistory } from 'vue-router'
import Welcome from '../views/Welcome.vue'
import ContactUs from '../views/ContactUs.vue'
import DemoRequest from '../views/DemoRequest.vue'
import ServiceProviderSolution from '../views/ServiceProviderSolution.vue'
import ServiceProviderProblem from '../views/ServiceProviderProblem.vue'
import BusinessBuyer from '../views/BusinessBuyer.vue'
import OurStory from '../views/OurStory.vue'

import ArticleOne from '../views/articles/ArticleOne.vue'
import ArticleTwo from '../views/articles/ArticleTwo.vue'
import ArticleThree from '../views/articles/ArticleThree.vue'
import ClientResults from '../views/ClientResults.vue'

const routes = [
  {
    path: "/",
    name: "welcome",
    component: Welcome,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactUs,
  },
  {
    path: "/demo",
    name: "demo",
    component: DemoRequest,
  },
  {
    path: "/achieve-bench-maximization",
    name: "service-provider-solution",
    component: ServiceProviderSolution,
  },
  {
    path: "/evolved-tech-project-access",
    name: "service-provider-problem",
    component: ServiceProviderProblem,
  },
  {
    path: "/empower-elastic-workforce",
    name: "empower-elastic-workforce",
    component: BusinessBuyer,
  },
  {
    path: "/new-tech-talent-marketplace",
    name: "new-tech-talent-marketplace",
    component: BusinessBuyer,
  },
  {
    path: "/story",
    name: "story",
    component: OurStory,
  },
  {
    path: "/articles",
    name: "articles",
    component: () => import(/* webpackChunkName: "articleList" */ "@/views/ArticleList.vue"),
  },
  {
    path: "/articles/understanding-the-growth-and-dynamics-of-the-it-professional-services-market",
    name: "ArticleOne",
    component: ArticleOne,
  },
  {
    path: "/articles/navigating-the-future-of-it-insights-from-canalys",
    name: "article2",
    component: ArticleTwo,
    meta: { title: "Article 2" },
  },
  {
    path: "/articles/ai-adoption-is-transforming-enterprises-insights-from-forbes",
    name: "article3",
    component: ArticleThree,
    meta: { title: "Article 3" },
  },
  {
    path: "/results",
    name: "results",
    component: ClientResults,
  },
  {
    path: "/not-found",
    name: "not-found",
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "@/views/errors/404.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/not-found",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

export default router