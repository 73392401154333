<template>
    <v-sheet>
        <section id="section1">
            <problem-section></problem-section>
        </section>
        <section id="value-statement">
            <value-statement></value-statement>
        </section>
        <section id="try-now">
            <try-now></try-now>
        </section>
        <section id="footer">
            <app-footer></app-footer>
        </section>
    </v-sheet>
</template>

<script setup>
  import ProblemSection from '@/components/service-providers/ProblemSection.vue';
  import ValueStatement from '@/components/service-providers/ValueStatement.vue';
  import TryNow from '@/components/service-providers/TryNow.vue';
  import AppFooter from '@/components/AppFooter.vue';
</script>

<script>
export default {
    name: "ServiceProviderPropblem",
    data: () => ({
       expand: true
    }),
};
</script>

<style scoped>

#section1 {
    background-size: 100vw 60vh;
    background-color: black;
}

#value-statement {
    margin-top: 90px;
    margin-bottom: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#try-now {
    background: url('@/assets/section2.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 25px;
    padding-bottom: 25px;
}

#footer {
    height: 10vh;
}
</style>