<template>
    <v-card variant="flat" class="mx-auto d-flex align-center justify-center" height="100%" min-height="600" min-width="300" :image="require('@/assets/section-four-top.png')" theme="dark" color="transparent">
        <v-card-text>
            <v-container>
            <v-row>
                <v-col cols="12" lg="4" md="6" sm="8">
                    <p class="text-h6 text-white">For Service Providers</p>
                    <p class="text-h3 text-white font-weight-bold">Unlock the full potential of your bench</p><br>
                    <br>
                    <p>
                        <v-btn variant="outlined" size="large" rounded="xl" color="white" class="pl-10 pr-10" @click="showDemoForm()">
                            {{$t('signUpDemo')}}
                        </v-btn>
                    </p>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "SolutionSection",
    methods: {
        showDemoForm() {
            document.getElementById("demo-request").scrollIntoView();
        }
    }
};
</script>