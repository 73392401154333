<template>
    <v-card variant="flat" color="#263238">
        <v-container class="py-16">
            <v-row>
                <v-col cols="12" class="text-center text-white">
                    <span class="text-h4">Hear what our partners have to say</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card class="mx-auto" color="transparent" variant="flat">
                    <v-carousel cycle :show-arrows="false" hide-delimiter-background>
                        <v-carousel-item :src="require('@/assets/images/testimonial1.png')"></v-carousel-item>
                        <v-carousel-item :src="require('@/assets/images/testimonial2.png')"></v-carousel-item>
                        <v-carousel-item :src="require('@/assets/images/testimonial3.png')"></v-carousel-item>
                        <v-carousel-item :src="require('@/assets/images/testimonial4.png')"></v-carousel-item>
                    </v-carousel>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            
        };
    },
    methods: {

    }
}
</script>

<style scoped>
.py-16 {
  padding-top: 64px;
  padding-bottom: 16px;
}
</style>