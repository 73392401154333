<template>
    <v-container id="try-now" class="bg-transparent-variant">
        <v-snackbar v-model="error" timeout="5000" location="top" width="800" rounded color="error">{{ errorDescription
            }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="error = false">
                    {{ $t("close") }}
                </v-btn>
            </template>
        </v-snackbar>
        <v-row>
                <v-col cols=12 lg=6 offset-lg="3" md=8 offset-md="2" xs="12" offset-xs="0">
                <v-card variant="outlined" class="mx-auto d-flex text-center pt-10 pb-10">
                    <v-card-text>
                        <p class="text-h4">
                            Pricing Plans
                        </p><br>
                        <v-row>
                            <v-col cols="12">
                                <p class="text-h5 font-weight-bold">
                                    Free for Year One
                                </p>
                                <p class="text-h6">
                                    One year free trial followed by a low-cost subscription model for your organization.
                                </p>
                            </v-col>
                            <br>
                            <v-col cols="12">
                                <p class="text-h5 font-weight-bold">
                                    Transaction Fee
                                </p>
                                <p class="text-h6">
                                    Your organization will only be billed when you engage a Service Provider and/or Consultant to employ for your project. A 10% transaction fee will be paid to ConnXions for connecting you to our expert network.
                                </p>
                                <p class="text-h6">
                                    For higher volume customers, please inquire about our volume discounts.
                                </p>
                            </v-col>
                            <br>
                            <v-col cols=12>
                                <p class="text-h5 font-weight-bold">
                                    Early Access Promotion
                                </p>
                                <p class="text-h6">
                                    Take advantage of our early access promotion with a 0% service fee on your first project
                                    up to $25,000.
                                </p>
                            </v-col>
                            <v-col cols="12" class="text-center align-center justify-center">
                                <p class="text-h5 font-weight-bold mb-3">
                                    {{ $t("getStartedToday") }}
                                </p>
                                <!-- <v-alert v-if="error" color="error" icon="$error" closable variant="outlined" :close-label="$t('close')" :text="errorDescription"></v-alert>-->
                                <v-form v-if="!progress && !success" ref="form" @submit.prevent="submit()"
                                    validation-on="submit" v-model="valid">
                                    <v-card variant="flat" class="mx-auto" max-width="500" color="transparent">
                                        <v-text-field name="preferredName" variant="outlined" type="input"
                                            v-model="form.preferredName" required :rules="rules.required"
                                            :placeholder="$t('preferredName')" autocomplete></v-text-field>
                                        <v-text-field name="company" variant="outlined" type="input"
                                            v-model="form.companyName" :placeholder="$t('companyName')" required
                                            :rules="rules.required" autocomplete></v-text-field>
                                        <v-text-field name="email" variant="outlined" type="email" v-model="form.email"
                                            :placeholder="$t('email')" required :rules="rules.email"
                                            autocomplete></v-text-field>
                                        <v-text-field name="title" variant="outlined" type="input" v-model="form.title"
                                            :placeholder="$t('title')" required :rules="rules.required"
                                            autocomplete></v-text-field>
                                        <!--
                                        <v-text-field name="website" variant="outlined" type="input"
                                            v-model="form.website" :placeholder="$t('website')" required
                                            :rules="rules.required" autocomplete></v-text-field>-->
                                        <v-btn theme=dark size="large" block rounded="xl" color="blue-accent-3"
                                            class="pl-10 pr-10" @click="submit()">
                                            {{ $t("signUp") }}
                                        </v-btn>
                                    </v-card>
                                </v-form>
                                <v-fade-transition hide-on-leave>
                                    <v-card v-if="progress" variant="flat" class="mx-auto" max-width="800">
                                        <v-card-text>
                                            <div class="py-12 text-center">
                                                <v-progress-circular color="blue-grey" :size="70"
                                                    indeterminate></v-progress-circular>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-fade-transition>
                                <v-fade-transition hide-on-leave>
                                    <v-card v-if="success" elevation="12" class="mx-auto" max-width="800">
                                        <v-card-text>
                                            <div class="py-12 text-center">
                                                <v-icon class="mb-6" color="success" icon="mdi-check-circle-outline"
                                                    size="128"></v-icon>
                                                <div class="text-h6 font-weight-bold">{{ $t('thanksForYourInterest') }}
                                                </div>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-fade-transition>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "SectionThree",
    computed: {
        api() {
            return process.env.VUE_APP_API_ENDPOINT + '/registrations';
        }
    },
    data() {
        return {
            valid: false,
            form: { type: "BUYER" },
            rules: {},
            progress: false,
            success: false,
            error: false,
            errorDescription: null
        };
    },
    methods: {
        submit() {
            this.rules = {
                required: [v => !!v || 'Required'],
                email: [
                    v => !!v || 'Required',
                    v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
                ]
            }
            this.$refs.form.validate().then(() => {
                this.progress = true;
                if (this.valid) {
                    this.axios.post(this.api, this.form, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(() => {
                        this.form = { type: "BUYER" };
                        this.success = true;
                        setTimeout(() => { this.success = false }, 5000);
                    }).catch((error) => {
                        this.error = true;
                        if (error.response.data.error === "DUPLICATE_KEY") {
                            this.errorDescription = "Your email is already registered."
                        } else {
                            this.errorDescription = error.response.data.errorDescription;
                        }
                    }).finally(() => {
                        this.rules = {};
                        this.progress = false;
                    });  
                }
            }).catch((error) => {
                console.log(error);
            });
        },
    },
};

</script>

<style scoped>



</style>