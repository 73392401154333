<template>
    <v-card variant="flat">
      <v-container>
        <v-row justify="center">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="text-center pa-3">
            <p class="text-h3">
              What is ConnXions?
            </p>
            <p class="text-h5">
              {{ $t('WHAT_IS') }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    </template>
  
  <script>
  export default {
    data() {
      return {
        
      }
    },
  }
  </script>
  
  <style scoped>
  .py-16 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  
  </style>