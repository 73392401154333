<template>
    <v-sheet>
        <section id="section1">
            <v-container class="bg-transparent-variant" style="height:80%">
                <v-row class="mb-16" no-gutters>
                    <v-col cols="5">
                        <span class="headline">Client</span>
                    </v-col>
                    <v-col cols="7"></v-col>
                    <v-col cols="5">
                        <span class="headline">Results</span>
                    </v-col>
                    <v-col cols="7"></v-col>
                </v-row>
            </v-container>
            <div id="background-overlay">
                <v-container>
                    <v-row class="mt-10">
                        <v-col cols="12">
                            <v-card variant="flat" color="transparent">
                                <v-card-text class="text-center">
                                    <p><span class="text-h2 font-weight-bold">Global Companies</span><span
                                            class="text-h2">&nbsp;trust ConnXions</span></p>
                                    <p class="text-h4">
                                        to match them with the best Certified Professionals
                                    </p>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <p class="text-h4">
                                        and more!
                                    </p>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </section>
        <section id="section2">
            <v-container>
                <v-row>
                    <v-col cols="12" class="text-center">
                        <p><span class="text-h1 text-orange">ConnXions</span><span
                                class="text-h1 text-white">&nbsp;Delivers</span></p>
                    </v-col>
                    <v-col cols="6">
                        <v-card variant="outlined" class="mx-auto rounded-xl" height="100%" max-width="100%"
                            theme="dark" color="white">
                            <v-card-text>
                                <v-container>
                                    <v-row align="center" style="height: 280px;">
                                        <v-col cols="4">
                                            <v-img rounded max-width="200" max-height="200"
                                                :src="require('@/assets/cost-savings.png')"></v-img>
                                        </v-col>
                                        <v-col>
                                            <p class="text-h2">Cost</p>
                                            <p class="text-h2 text-white font-weight-bold">Savings</p>
                                            <p class="text-h6">
                                                Optimize your budget by leveraging certified resources through our
                                                expert project management tools and seamless project workflow, unlocking
                                                maximum value while minimizing cost.
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card variant="outlined" class="mx-auto rounded-xl" height="100%" max-width="100%"
                            theme="dark" color="white">
                            <v-card-text>
                                <v-container>
                                    <v-row align="center" style="height: 280px;">
                                        <v-col cols="4">
                                            <v-img rounded max-width="200" max-height="200"
                                                :src="require('@/assets/faster-turnaround.png')"></v-img>
                                        </v-col>
                                        <v-col>
                                            <p class="text-h2 text-white">Faster</p>
                                            <p class="text-h2 text-white font-weight-bold">Turnaround</p>
                                            <p class="text-h6">
                                                Leveraging our platform to connect with skilled professionals, clients
                                                have enjoyed accelerated turnaround times, empowering them to outpace
                                                competitors and maintain a competitive edge.
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card variant="outlined" class="mx-auto rounded-xl" height="100%" max-width="100%"
                            theme="dark" color="white">
                            <v-card-text>
                                <v-container>
                                    <v-row align="center" style="height: 280px;">
                                        <v-col cols="4">
                                            <v-img rounded max-width="200" max-height="200"
                                                :src="require('@/assets/improved-efficiency.png')"></v-img>
                                        </v-col>
                                        <v-col>
                                            <p class="text-h2 text-white">Improved</p>
                                            <p class="text-h2 text-white font-weight-bold">Efficiency</p>
                                            <p class="text-h6">
                                                Unlock heightened efficiency with streamlined processes and access to
                                                premier talent, enabling clients to achieve remarkable results while
                                                optimizing resources.
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card variant="outlined" class="mx-auto rounded-xl" height="100%" max-width="100%"
                            theme="dark" color="white">
                            <v-card-text>
                                <v-container>
                                    <v-row align="center" style="height: 280px;">
                                        <v-col cols="4">
                                            <v-img rounded max-width="200" max-height="200"
                                                :src="require('@/assets/client-satisfaction.png')"></v-img>
                                        </v-col>
                                        <v-col>
                                            <p class="text-h2 text-white">Client</p>
                                            <p class="text-h2 text-white font-weight-bold">Satisfaction</p>
                                            <p class="text-h6">
                                                Experience unparalleled satisfaction with our platform's streamlined
                                                processes and access to certified talent, guaranteeing seamless
                                                operations and exceptional productivity.
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <section id="section3">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-card class="card" theme="dark" variant="outlined">
                            <v-card-text>
                                <p class="text-h4">Hear what our clients have to say...</p>
                                <client-testimonials></client-testimonials>
                            </v-card-text>

                        </v-card>
                        <br>
                        <br>
                    </v-col>

                    <v-col cols="12">

                        <v-card variant="outlined" class="mx-auto rounded-xl" height="100%" :image="require('@/assets/experience-connxions.png')" max-width="100%" theme="dark" color="white">
                    <v-card-text>
                    <v-row class="mt-16 mb-16 ml-10">
                        <v-col cols="5">
                            <p class="text-h2">Experience ConnXions</p>
                            <p class="text-h2 font-weight-bold">for Yourself</p><br>
                            <p class="text-h6">
                                Ready to see your business thrive with the power of ConnXions?Join now and experience firsthand how our platform will revolutionize your next project.
                            </p>
                            <br>
                            <p>
                                <v-btn variant="outlined" size="large" rounded="xl" color="white" class="pl-10 pr-10">
                {{$t('Search for Talent')}}
            </v-btn>
                            </p>
                        </v-col>
                    </v-row>
                </v-card-text>
                </v-card>

                    </v-col>
                    
                </v-row>
            </v-container>

        </section>

        <section id="footer">
            <app-footer></app-footer>
        </section>

    </v-sheet>
</template>

<script setup>
import ClientTestimonials from '@/components/ClientTestimonials.vue';
import AppFooter from '@/components/AppFooter.vue';
</script>

<script>
export default {
    name: "ClientResults"
};
</script>

<style scoped>
#section1 {
    background: url('@/assets/our-story-background.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#background-overlay {
    background: url('@/assets/making-connections-background.png');
    position: absolute;
    color: white;
    left: 0;
    top: 50%;
    right: 0;
    bottom: 0;
}

#section2 {
    background: url('@/assets/connxions-delivers-background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#section3 {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.headline {
    color: black;
    line-height: 130px;
    font-size: 8em;
}

#footer {
    height: 10vh;
}

</style>