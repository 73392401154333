<template>
    <v-sheet>
        <section id="hero-section">
            <hero-section></hero-section>
        </section>
        <section id="section2">
            <what-is></what-is>
        </section>
        <section id="section2">
            <section-two></section-two>
        </section>
        <section id="section4">
            <our-benefits></our-benefits>
        </section>
        <section id="section3">
            <section-three></section-three>
        </section>
        <section id="section4">
            <our-story></our-story>
        </section>
        <section id="footer">
            <app-footer></app-footer>
        </section>
    </v-sheet>
</template>

<script setup>
  import HeroSection from '@/components/welcome/HeroSection.vue';
  import WhatIs from '@/components/welcome/WhatIs.vue';
  import SectionTwo from '@/components/SectionTwo.vue';
  import SectionThree from '@/components/SectionThree.vue';
  import OurBenefits from '@/components/OurBenefits.vue';
  import OurStory from '@/components/OurJourney.vue';
  //import DiveDeeper from '@/components/DiveDeeper.vue';
  import AppFooter from '@/components/AppFooter.vue';
</script>

<script>
export default {
    name: "WelcomeView",
    data: () => ({
    }),
};
</script>

<style scoped>

#hero-section {
    background-size: 100vw 60vh;
    background-color: black;
}

#section2 {
    margin-top: 90px;
    margin-bottom: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#section3 {
    background: url('@/assets/section2.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#section4 {
    padding-top: 50px;
    padding-bottom: 50px;
    background: url('@/assets/section2.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

#footer {
    height: 10vh;
}
</style>