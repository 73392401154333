<template>
  <div id="app">
    <DefaultLayout>
      <router-view />
    </DefaultLayout>
  </div>
</template>

<script>
import DefaultLayout from '@/layouts/default/DefaultLayout'
export default {
  name: 'App',
  components: { DefaultLayout },
  metaInfo: {
    title: 'ConnXions',
  },
}
</script>
