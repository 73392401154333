<template>
  <v-sheet>
      <section id="section1">
          <our-story></our-story>
      </section>
      <section id="footer">
          <app-footer></app-footer>
      </section>
  </v-sheet>
</template>

<script setup>
import OurStory from '@/components/OurJourney.vue';
import AppFooter from '@/components/AppFooter.vue';
</script>

<script>
export default {
    name: "OurStory",
    data: () => ({
    }),
};
</script>

<style>

#section1 {
    background-size: 100vw 60vh;
    background-color: black;
}

#footer {
    height: 10vh;
}
</style>