<template>
  <v-card variant="flat" class="mx-auto d-flex align-center justify-center" height="100%" min-height="600" min-width="300" :image="require('@/assets/images/welcome-hero.png')" theme="dark" color="transparent">
    <span class="headline">
      <img class="hero-image" :src="require('@/assets/logo-landscape.png')" size="300">
      <p>{{ $t('HEADLINE') }}</p>
      <p class="text-h6 text-white">{{ $t('SUB_TITLE') }}</p>
      <v-btn size="large" variant="flat" rounded="xl" width="180"
                    color="#2979FF" class="pl-10 pr-10 mr-1" to="/empower-elastic-workforce">
                    {{ $t('CONSULTANT') }}
                </v-btn>
                <v-btn size="large" variant="flat" rounded="xl" width="180"
                    color="#EE8254" class="pl-10 pr-10 ml-1" to="achieve-bench-maximization">
                    {{ $t('PROJECT') }}
                </v-btn>
    </span>
  </v-card>

</template>

<script>
export default {
    name: "SectionOne",
    data: () => ({

    }),
};
</script>

<style scoped>
.headline {
    color: white;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 10px;
    text-align: center;
    margin: auto;
}

.hero-image {
  width: 20%;
  height:auto;
}

.frame {
    width: 100%;
    height: 50%;
    opacity: 1.0;
    padding: 8px;
    display: grid;
    place-content: start space-around;
    text-align: center;
}

@keyframes slide-in {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0%);
    }
}

.frame {
    animation: slide-in 1000ms;
}


@media screen and (min-width: 961px) {
  span.headline {
    font-size: 4em;
    line-height: 90px;
    max-width: 1200px;
    
  }
}

@media screen and (max-width: 960px) {
  span.headline {
    font-size: 4em;
    line-height: 70px;
    max-width: 1200px;
  }
}

@media screen and (max-width: 600px) {
  span.headline {
    font-size: 3em;
    line-height: 65px;
  }
}

@media (max-width: 960px) and (min-width: 300px) {
  .hero-image {
    width: 30%;
  }
}

@media (max-width: 600px) and (min-width: 300px) {
  .hero-image {
    width: 50%;
  }
}

</style>